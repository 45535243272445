<template>
  <div :class="`titlebox ${titleclass}`">
    <h3 v-if="subtitle" :class="`d-flex align-items-center ${classlist}`">
      <span class="sq"></span>
      {{ subtitle }}
    </h3>
    <h2>{{ title }}</h2>
    <div v-html="text" class="mb-30"></div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle", "classlist", "text", "titleclass"],
};
</script>

<style></style>
