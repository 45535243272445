import axios from "axios";
const direction = {
  state: () => ({
    direction: {},
    directions: [],
  }),
  getters: {
    direction(state) {
      return state.direction;
    },
    directions(state) {
      return state.directions;
    },
    dirday(state) {
      return state.directions.filter((dir) => dir.kind == 0);
    },
    dirnight(state) {
      return state.directions.filter((dir) => dir.kind == 1);
    },
  },
  mutations: {
    direction(state, payload) {
      state.direction = payload;
    },
    directions(state, payload) {
      state.directions = payload;
    },
  },
  actions: {
    allDirection(context) {
      context.dispatch("getAxios", "direction/all").then((res) => {
        console.log(res.data);
        context.commit("directions", res.data);
      });
    },
    async getDirectionByCode({ getters }, payload) {
      return await axios.get(`${getters.uri}/direction/code/${payload}`);
    },
    async getDirection(context, payload) {
      let res = await context.dispatch("getAxios", `direction/get/${payload}`);
      // console.log(res.data);
      context.commit("direction", res.data);
    },
    async returnGetDirection(context, payload) {
      return await context.dispatch("getAxios", `direction/get/${payload}`);
    },
  },
};

export default direction;
